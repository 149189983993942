<template>
  <Page>
    <template v-slot:content>
      <v-sheet
        elevation="3"
        rounded
      >
        <v-data-table
          :footer-props="table.footerProps"
          :headers="table.headers"
          :items="result.items"
          :loading="result.loading"
          :options.sync="table.options"
          :page="table.options.page"
          :server-items-length="result.count"
          @update:page="load"
          @update:sort-by="load"
          @update:sort-desc="load"
        >
          <template v-slot:loading>
            <v-sheet
              class="text-center pa-6"
            >
              {{ $t('global.loading') }}
            </v-sheet>
          </template>
        
          <template v-slot:no-data>
            <v-sheet
              class="text-center pa-6"
            >
              {{ $t('global.no-data') }}
            </v-sheet>
          </template>
        
          <template v-slot:item.edition="{ item }">
            <v-btn
              icon
              :href="'/admin/categories/' + item.id"
            >
              <v-icon
                color="blue lighten-1"
              >
                mdi-pencil-outline
              </v-icon>
            </v-btn>
          </template>
        
          <template v-slot:item.name="{ item }">
            <v-avatar
              class="ma-3 text-h5 font-weight-light white--text"
              color="indigo"
              size="30"
              rounded
            >
              <v-img
                v-if="item.media_url"
                :src="'https://www.imparfaite.com/static/' + item.media_url"
              />
              <span v-else>
                {{ item.short_names.fr.charAt(0) }}
              </span>
            </v-avatar>
            {{ item.short_names.fr }}
          </template>
        
          <template v-slot:item.targets="{ item }">
            <TargetChip
              v-for="(target, index) in item.targets"
              :key="`target-${index}`"
              :target="target"
              class="mr-2"
            />
          </template>
        </v-data-table>
      </v-sheet>
    </template>
  </Page>
</template>

<script>
import { getQueryString } from '@/helpers/queryString'
import Page from '@/components/core/Page.vue'
import TargetChip from '@/components/base/TargetChip.vue'

export default {
  name: 'CategoriesList',
  components: { Page, TargetChip },
  data() {
    const itemsPerPage = 30
    const currentPage = +this.$route.query.page || 1
    
    return {
      request: null,
      filters: {
      },
      result: {
        items: [],
        count: 0,
        loading: false,
      },
      table: {
        footerProps: {
          'items-per-page-options': [itemsPerPage],
          'show-first-last-page': true,
          'show-current-page': true,
        },
        headers: [
          {
            value: 'edition',
            text: '',
            width: 30,
            sortable: false,
          },
          {
            value: 'name',
            text: 'Nom',
            sortable: false,
          }, {
            value: 'slug',
            text: 'Slug',
            sortable: false,
          }, {
            value: 'weight',
            text: 'Poids',
            sortable: false,
          }, {
            value: 'targets',
            text: 'Cibles',
            sortable: false,
          },   {
            value: 'priority',
            text: 'Priorité',
            sortable: false,
          },
        ],
        options: {
          page: currentPage,
          itemsPerPage: itemsPerPage,
          sortBy: ['created_at'],
          sortDesc: [true],
          multiSort: false,
        },
      },
    }
  },
  computed: {
    queryString: function () {
      return 'v3/categories?' + getQueryString(
        this.table.options.page,
        this.filters,
        this.table.options.sortBy,
        this.table.options.sortDesc
      )
    },
  },
  watch: {
    filters() {
      this.table.options.page = 0
    },
    queryString: {
      handler() {
        this.load()
      },
    },
    'table.options.page': function(value) {
      this.$router.replace({ name: 'AdminCategoriesList', query:
          { ...this.filters, page : value } }
      )
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    onFiltersChanged(filters) {
      this.filters = filters
      this.$router.replace({ name: 'AdminCategoriesList', query:
          { ...this.filters, page : this.table.options.page } })
    },
    cancel() {
      if (this.request) {
        this.request.cancel('aborted')
        this.request = null
      }
    },
    load() {
      this.cancel()
      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }
      this.result.loading = true
      this.result.items = []
      this.result.count = 0

      this.$axios.get(this.queryString, { cancelToken: axiosSource.token })
        .then((response) => {
          this.result.items = response.data['hydra:member']
          this.result.count = response.data['hydra:totalItems']
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error)
        })
        .finally(() => {
          this.request = null
          this.result.loading = false
        })
    },
    // getCategoryName(category) {
    //
    // },
  },
}
</script>

<style lang="scss">
</style>
